@import 'variables';

.app {
  min-height: 100vh;
  background-color: $appBackground;
  display: flex;
  flex-direction: column;

  .page {
    flex: 1;
    padding: ($headerHeight + $pageSpacing) 0 $pageSpacing;
  }
}
