.footer {
  background: black;
  color: #adadad;
  padding: 15px 0;
}

.container {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 450px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}

.menu {
  list-style-type: none;
  padding: 0;
  margin: 0;

  > li {
    display: inline-block;
    margin-left: 10px;
    padding: 0;

    > a { color: #adadad; }
  }

  @media screen and (max-width: 450px) {
    padding: 10px 0;
    text-align: center;

    > li { margin: 0 10px 10px; }
  }
}
