.container {
  position: relative;
  padding: 45px 0;
}

.img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center var(--banner-offset, 50%);
  z-index: 0;
  user-select: none;
  pointer-events: none;
}

.content {
  position: relative;
  z-index: 1;
  display: flow-root;
  text-shadow: 1px 0 4px rgba(0,0,0,.8);
}
