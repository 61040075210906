@import '../../variables';

.container {
  padding-top: 56.25%;
  width: 100%;
  position: relative;
  background-color: black;
  border: 2px solid $appPrimary;
  border-radius: 2px;
}

.facade, .frame {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.facade {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;

  &:before {
    content: '';
    background-image: url('./yt-play-btn.svg');
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 68px;
    height: 48px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    pointer-events: none;
  }

  &:hover:before {
    filter: grayscale(1);
  }
}
