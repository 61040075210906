$appBackground: #1e1d1e;
$appPrimary: #2d79ae;
$highlightText: #42b0fd;

$headerHeight: 56px;
$pageSpacing: 45px;

:export {
  appBackground: $appBackground;
  appPrimary: $appPrimary;
  highlightText: $highlightText;
}
