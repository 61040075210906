@use 'sass:math';
@import '../../variables';

.headline {
  font-size: 35px;
  margin: 0 0 45px;
}

.container {
  &.bottom { padding-bottom: 45px; }

  &.top {
    padding-top: 45px;
    border-top: 2px solid #262627;
  }
}

.introHeader {
  background-color: #131213;
  padding: calc(100px + #{$headerHeight}) 0 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 8px solid $appPrimary;
  margin-top: ($headerHeight + $pageSpacing) * -1;
  margin-bottom: $pageSpacing;
  position: relative;
  text-align: center;
}

.introLogo {
  position: relative;
  z-index: 1;
  padding: 25px;
}

.icon80 {
  fill: #fff;
  width: 80px;
  height: 80px;
  display: block;
  margin: 0 auto;
}

.icon60 {
  @extend .icon80;
  width: 60px;
  height: 60px;
}

.enbold {
  font-weight: 400;
  font-size: 1.3rem;
}
