@keyframes imageAppear {
  from { opacity: 0; }
  to { opacity: 1; }
}

.slideshowImg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  z-index: 0;

  &.appear {
    animation: imageAppear .5s ease-out;
  }
}
