@use 'sass:math';
@import '../../variables';

@mixin dynamic-font-size($font, $break) {
  @media (max-width: $break) {
    font-size: (math.div($font, $break) * 100vw);
  }
}

.linkBox {
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  border: 1px solid currentColor;
  padding: 8px;
  display: inline-block;
}

.supertitle {
  text-transform: uppercase;
  font-weight: 600;
}

.pubAmHeadline {
  color: white;
  line-height: 1;
  font-size: 70px;

  @include dynamic-font-size(70px, 450px);
}
