@import "../../variables";

@keyframes drawerShow {
  from { transform: translateX(100%); }
  to { transform: translateX(0%); }
}

@keyframes drawerHide {
  from { transform: translateX(0%); }
  to { transform: translateX(100%); }
}

@keyframes shadowShow {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes shadowHide {
  from { opacity: 1; }
  to { opacity: 0; }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
  font-size: 17px;
  z-index: 10000;
  height: $headerHeight;
  padding: 4px 0;

  .navMenu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    height: 100%;

    .navMenuItem {
      display: block;
      padding: 8px 10px;
      color: white;
      text-decoration: none;
      background: transparent;
      text-transform: uppercase;
      position: relative;
      border-radius: 1px;
      transition: background .15s;

      &:after {
        content: "";
        position: absolute;
        width: 0%;
        height: 3px;
        background: $appPrimary;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        transition: width 0.3s;
        border-radius: 2px;
      }

      &:hover { background: rgba(255, 255, 255, 0.1); }

      &.active:after { width: 100%; }
    }
  }

  .wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1170px;
  }
}

.drawerNavContainer {
  flex: 1;
  overflow: auto;
}

.drawerMenu {
  background: rgba(0, 0, 0, 0.95);
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10001;
  max-width: 100vh;
  width: 300px;
  display: flex;
  flex-direction: column;
  animation: drawerShow .3s ease-in-out;

  &.leaving {
    animation: drawerHide .3s ease-in-out;
    transform: translateX(100%);
  }

  .drawerNav {
    list-style-type: none;
    margin: 0;
    padding: 8px 15px;

    .drawerNavItem {
      display: block;
      padding: 10px 15px 10px 12px;
      color: white;
      text-decoration: none;
      background: transparent;
      text-transform: uppercase;
      border-left: 3px solid transparent;
      border-radius: 1px;

      &:hover { background: rgba(255, 255, 255, 0.1); }

      &.active { border-left-color: $appPrimary; }
    }
  }
}

.drawerShadow {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  animation: shadowShow .3s ease-in-out;

  &.leaving {
    animation: shadowHide .3s ease-in-out;
    opacity: 0;
  }
}

.drawerHeader {
  height: $headerHeight;
  padding: 4px 15px;
}

%navBtn {
  border: none;
  background: transparent;
  width: 48px;
  height: 48px;
  padding: 0;
  border-radius: 2px;
  border: 2px solid transparent;
  transition: border-color .3s, background .3s;
  cursor: pointer;

  &:hover {
    border-color: rgba(255,255,255,.4);
    background: rgba(255,255,255,.1);
  }
}

.burger {
  @extend %navBtn;

  .bun {
    height: 4px;
    margin: 6px auto;
    width: 34px;
    background: white;
    border-radius: 1px;
  }
}

%lines {
  content: "";
  width: 80%;
  height: 3px;
  background: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1px;
}

.closeBtn {
  @extend %navBtn;

  position: relative;
  float: right;

  &:before {
    @extend %lines;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    @extend %lines;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.brand {
  color: white;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 20px;

  &:hover { text-decoration: none; }

  .brandLine { line-height: 1; }

  img {
    height: 46px;
    margin-right: 8px;
    width: auto;
  }

  .brandLetter {
    color: $appPrimary;
    font-size: 28px;
    font-weight: 600;
  }
}

.valignMiddle { vertical-align: middle; }
