.wrapper {
  max-width: 1000px;
  padding: 0 15px;
  margin: 0 auto;
  width: 100%;

  main > &:not(:last-child) {
    padding-bottom: 45px;
  }
}
