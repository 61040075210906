@import 'variables';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: white;
  background-color: $appBackground;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

h1, h2, h3,
h4, h5, h6 {
  text-transform: uppercase;
  text-align: center;
}

h3 {
  color: $appPrimary;
  margin: 0 0 45px;
}

hr {
  border: none;
  height: 2px;
  background-color: #262627;
  width: 100%;
}

a {
  color: $highlightText;
  text-decoration: none;

  &:hover { text-decoration: underline; }
}

img {
  max-width: 100%;
  height: auto;
}

.no-support {
  font-size: 20px;
  text-transform: uppercase;
  text-align: center;

  & &-img {
    border-bottom: 5px solid #2d79ae;
    border-top: 56px solid black;
    padding: 100px 0;
    margin-bottom: 45px;
    background-color: #131213;

    img { padding: 25px; }
  }
}
